






































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { RouterItem } from './model/router-item';
import { version } from '../package.json';
import { firebaseApp } from './plugins/firebase';

@Component
export default class App extends Vue {
  analyticsSnackbar = false;
  routes: RouterItem[] = [
    {
      title: 'Home',
      route: '/home',
      icon: 'mdi-home',
    },
    {
      title: 'CV',
      route: '/cv',
      icon: 'mdi-face',
    },
    {
      title: 'Projects',
      route: '/projects',
      icon: 'mdi-tablet-cellphone',
    },
    {
      title: 'Social',
      route: '/social',
      icon: 'mdi-share-variant',
    },
  ];

  private readonly ANALYTICS_RESULT = 'analyticsResult';

  mounted() {
    const analyticsResult = localStorage.getItem(this.ANALYTICS_RESULT);
    if (analyticsResult == null) {
      this.analyticsSnackbar = true;
    } else {
      firebaseApp.automaticDataCollectionEnabled = Boolean(analyticsResult);
    }
  }

  get currentVersion(): string {
    return `v${version}`;
  }

  dismiss(result: boolean): void {
    localStorage.setItem(this.ANALYTICS_RESULT, String(result));
    firebaseApp.automaticDataCollectionEnabled = result;
    this.analyticsSnackbar = false;
  }
}
