import Vue from 'vue';
import Router from 'vue-router';
import Home from './components/Home.vue';
import NotFound from './components/NotFound.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  // base: '',
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('./components/Projects.vue'),
    },
    {
      path: '/cv',
      name: 'cv',
      component: () => import('./components/CV.vue'),
    },
    {
      path: '/social',
      name: 'social',
      component: () => import('./components/Social.vue'),
    }
    // {
    //   path: '*',
    //   component: NotFound,
    // },
  ],
});
